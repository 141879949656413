import { tick } from "../assets";
import { useShowJoinModal, useToggleCommunityModal } from "../context"

export default function JoinCommunity() {
    const show = useShowJoinModal();
    const toggle = useToggleCommunityModal();

    return show ? <div id='modal-2' onClick={(e) => {
        if (e.target === document.querySelector('#modal-2')) {
            toggle()
        }
    }} className='cursor-pointer fixed top-0 px-5 w-full h-screen bg-[#121212]/60 flex justify-center items-center z-[100]'>
        <div className='w-full max-w-sm rounded-lg bg-white px-6 py-10 grid gap-3' data-aos="fade-up" data-aos-duration="1000">
            <img src={tick} className="mx-auto"/>
            <h1 className='text-lg font-semibold text-center'>Expect to hear from us soon!</h1>
            <p className="text-center text-sm">
                While you await what we have cooking... Join fellow 360 members on our WhatsApp community.
                Connect, vibe and have fun!
            </p>
            <a href="https://chat.whatsapp.com/JdyatZTNIuLBSoXAmmZozH"
                onClick={toggle}
                target="_blank"
                className='butt border w-full h-fit text-sm !text-white !bg-[#023047] border-[#1F2937] mt-2'>Join the Community!</a>
        </div>
    </div> : <></>
}