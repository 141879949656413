import React from 'react';
import { joinWaitList } from '../api';
import { toast } from 'react-toastify';
import Loading from 'react-loading';
import { useShowModal, useToggleCommunityModal, useToggleModal } from '../context';


export default function JoinWaitListModal() {
    const show = useShowModal();
    const toggle = useToggleModal();
    const toogleCommunity = useToggleCommunityModal()
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");

    const forString = React.useMemo(() => {
        return show && show === "all" ? "" : "for " + show 
    }, [show]) 

    async function handleSubmit() {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            toast.error("Enter a valid email format");
            return
        }

        setLoading(true);
        await joinWaitList(email, show && show !== "all" ? show :  "");
        setLoading(false);
        toggle();
        toogleCommunity()
    }
    return show ? <div id='modal' onClick={(e) => {
        if (e.target === document.querySelector('#modal')) {
            toggle()
        }
    }} className='cursor-pointer fixed top-0 px-5 w-full h-screen bg-[#121212]/60 flex justify-center items-center z-[100]'>
        <div className='w-full h-56 max-w-sm rounded-lg bg-white px-6 py-10 grid gap-5' data-aos="fade-up" data-aos-duration="1000">
            {
                !loading ? <>
                    <h1 className='text-xl font-semibold'>Join The Waitlist {forString}</h1>
                    <input onBlur={e => setEmail(e.target.value.trim())} value={email} onChange={e => setEmail(e.target.value)} placeholder='Enter email address' className='custom-input border w-full h-10' />
                    <button onClick={handleSubmit} className='butt border w-full text-sm border-[#121212]'>Submit</button>
                </> : <div className='flex flex-col justify-center items-center gap-4'>
                    <Loading type='spin' color='#121212' className='!w-10 !h-10' />
                    <p>Adding you to waitlist {forString}..</p>
                </div>
            }
        </div>
    </div> : <></>
}