import React from 'react';
import { logo } from '../assets';
import { useToggleModal } from '../context';

export default function Navbar() {
    const toggle = useToggleModal()
    return <nav className='bg-[#121212]'>
        <div className='h-14 tutoria-container flex justify-between items-center'>
            <img src={logo} className='w-10' alt='Logo' />

            <div className='flex gap-4'>
                <h5 className='text-white text-xs text-right font-semibold hidden md:block'>
                    Join the waitlist. <br />
                    Be rewarded for life.
                </h5>
                <button onClick={() => toggle()} className='butt text-xs'>Join Waitlist</button>
            </div>
        </div>
    </nav>
}