import { useToggleModal } from "../context";
import CourseCard from "./widgets/CourseCard";
import CourseGrid from "./widgets/CourseGrid";

const courses = [
    {code : "SSC105", name : "Mathematics for Social Sciences"},
    {code : "CHM101", name : "Introductory Chemistry I"},
    {code : "MTH105", name : "Mathematics for Biological Sciences"},
    {code : "MTH101", name : "Elementary Mathematics I"},
    {code : "MEE205", name : "Engineering Mechanics I"},
    {code : "PHY101", name : "Introductory Physics I"},
    {code : "MTH201", name : "Mathematical Methods I"},
    {code : "PHY105", name : "Physics for Biological Sciences"},
    {code : "AEC201", name : "Introduction to Microeconomics"},
]
export default function Courses() {
    const toggle = useToggleModal();
    return <div className="tutoria-container py-20 text-[#121212]">
        <h1 className="text-center text-4xl lg:text-5xl font-semibold" data-aos="fade-up"  data-aos-duration="500">Courses currently cooking</h1>
        <p className="text-center mt-5 max-w-xl text-lg mx-auto mb-10" data-aos="fade-up"  data-aos-duration="1000">Discover our diverse array of tailored courses designed to improve your learning experience and achieve exam success</p>
        <CourseGrid>
            {
                courses.map((course, index) => <CourseCard {...course} key={"courses" + index}/>)
            }
        </CourseGrid>
        <div className="flex mt-5">

        <button onClick={() => toggle()} className='border border-[#121212] mx-auto butt'>Join Waitlist</button>
        </div>
    </div>
}