import { art } from '../assets';
import TutoriaAdvantages from '../data/advantages';

const fadeOptions = [
    "fade-down-right",
    "fade-down-left",
    "fade-up-right",
    "fade-up-left",
]
export default function MidSection() {
    return <section className='bg-[#121212] relative'>
        <img src={art} alt='art' className='absolute w-[700px]  lg:bottom-0' />

        <div className='grid lg:grid-cols-2 gap-10 tutoria-container relative py-20'>

            <div className='my-auto max-w-sm font-semibold p-4 rounded-lg bg-[#023047]/90 text-4xl text-white'>
                <h3>
                    The new way to learn faster, efficiently, anytime, and anywhere.
                </h3>
            </div>

            <ul className='grid grid-cols-2 gap-3'>
                {
                    TutoriaAdvantages.map(
                        (advantage, ind) => {
                            const titleSplit = advantage.title.split(" ")
                            const titleHtml = `${titleSplit.shift()} <br /> ${titleSplit.join(' ')}`
                            return <li key={"advantages" + ind}
                                className='bg-white p-4 md:p-8 rounded-lg gap-3 flex flex-col justify-between'
                                data-aos={fadeOptions[ind]}
                                data-aos-duration="1000"
                            >
                                <div className='w-10 h-10 flex rounded-full bg-black'>
                                    <img className='w-4 m-auto' src={advantage.icon} alt={"section"} />
                                </div>

                                <h1 className='font-bold' dangerouslySetInnerHTML={{
                                    __html : titleHtml
                                }}/>

                                <p className='text-xs'>{advantage.content}</p>
                            </li>
                        }
                    )
                }
            </ul>
        </div>
    </section>
}