import React from "react";

interface IContext {
    showModal: string | null
    setShowModal: React.Dispatch<React.SetStateAction<string | null>>
    showJoinModal: boolean
    setShowJoinModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Context = React.createContext<IContext>({} as IContext);

export default function ContextProvider({ children }: React.PropsWithChildren) {
    const [showModal, setShowModal] = React.useState<string | null>(null);
    const [showJoinModal, setShowJoinModal] = React.useState(false);

    return <Context.Provider value={{
        showModal, setShowModal,
        showJoinModal, setShowJoinModal
    }}>
        {children}
    </Context.Provider>
}


export function useToggleModal() {
    const { setShowModal } = React.useContext(Context);
    return (code = "all") => setShowModal(show => show ? null : code)
}


export function useShowModal() {
    const { showModal } = React.useContext(Context);
    return showModal;
}

export function useToggleCommunityModal() {
    const { setShowJoinModal } = React.useContext(Context);
    return () => setShowJoinModal(show => !show)
}

export function useShowJoinModal() {
    const { showJoinModal } = React.useContext(Context);
    return showJoinModal
}