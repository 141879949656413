import React from "react"
import CourseCard from "./CourseCard"

export default function CourseGrid({ children }: React.PropsWithChildren) {
    return <div className={`tutoria-container 
            overflow-auto pb-5 
            grid gap-6 auto-cols-max 
            grid-rows-2 grid-flow-col 
            lg:grid-flow-dense
            lg:grid-rows-none lg:grid-cols-3 
            xl:grid-cols-4`}>
        {children}
    </div>
}