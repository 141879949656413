import { icon1, icon2, icon3, icon4 } from "../assets";

const TutoriaAdvantages = [
    {
        icon : icon1,
        title : "Value For Money",
        content : "Best value, high-quality tutorials, past questions, materials, AI learning assistant, test takers all for a tiny price."
    },
    {
        icon : icon2,
        title : "Curbing Information Overload",
        content : "Curriculum-based curated content, focused learning, stress-free experience – all from your comfort zone"
    },
    {
        icon : icon3,
        title : "More Than School",
        content : "Unlock a world of possibilities! Sharpen skills and knowledge with diverse courses. Endless growth, right at your fingertips."
    },
    {
        icon : icon4,
        title : "Full Package",
        content : "Everything you need to achieve academic and all-round success, all in one place. Unleash your true potentials with us."
    },
]

export default TutoriaAdvantages;

