import axios from 'axios';
import { toast } from 'react-toastify';

export async function joinWaitList(email: string, code = "") {
  try {
    await axios.post(`https://backend.tutoria360.com//waitlist/add`, {
      email,
      ...(code && { code })
    })
    toast.success("You have been added to the waitlist successfully")
  } catch (e: any) {
    toast.error(e?.response?.data?.message || e?.message)
  }
}