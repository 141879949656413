import { logoblack, batchblack, facebook, instagram, twitter } from '../assets';

export default function EndSection() {
    
    return <div className='py-14 tutoria-container'>
        <ul className='justify-items-center md:justify-items-start grid md:grid-cols-11 max-w-2xl gap-y-6 mx-auto'>
            <li className='flex flex-col justify-between gap-1 md:col-span-3 max-w-[300px] md:max-w-none text-center '>
                <h2 className='font-semibold text-lg'>Discounts and Rewards</h2>
                <p className=''>Access up to 100% discount and 5000 NGN 360 credits</p>
            </li>

            <span className='h-[90%] w-0 border-r m-auto border-[#121212] hidden md:block' />
            <li className='flex flex-col justify-between  md:col-span-3 max-w-[300px] md:max-w-none text-center '>
                <h2 className='font-semibold text-lg'>Priority testers</h2>
                <p className=''>Get access to paid contents for free as priority testers</p>
            </li>
            <span className='h-[90%] w-0 border-r m-auto border-[#121212] hidden md:block' />
            <li className='flex flex-col justify-between gap-3 md:gap-1  md:col-span-3 max-w-[300px] md:max-w-none text-center '>
                <div className='flex justify-center mg:justifu-start gap-1'>
                    <img className='h-8' src={logoblack} alt='logo' />
                    <img className='h-8' src={batchblack} alt='logo' />
                </div>
                <p className=''>Join the 360 geng. Win gifts,  merch, and many more</p>
            </li>
        </ul>

        <hr className='max-w-3xl mx-auto border-[#121212] my-7' />

        <div className='grid gap-4'>
            <h4 className='mx-auto text-xl max-w-lg text-center font-semibold'>Be one of the first 5,000 to reserve your spot on the borderless waitlist.</h4>

            <a href="https://chat.whatsapp.com/JdyatZTNIuLBSoXAmmZozH" target='_blank' className='border border-[#121212] mx-auto butt'>Join Our Community</a>

        </div>


        <div className='flex flex-col items-center mt-14'>
            <p className='font-bold'>Follow Us</p>
            <ul className='grid grid-cols-3 gap-4 mt-5'>
                <li data-aos="fade-left">
                    <a target='__blank' href='https://www.facebook.com/profile.php?id=100095463641284'>
                        <img src={facebook} alt='social media' className='w-8' />
                    </a>
                </li>
                <li data-aos="fade-left">
                    <a target='__blank' href='https://www.instagram.com/tutoria360ng'>
                        <img src={instagram} alt='social media' className='w-8' />
                    </a>
                </li>
                <li data-aos="fade-left">
                    <a target='__blank' href="https://x.com/tutoria360ng?t=HhApvu_Ft8gxlu2xwd937g&s=09">
                        <img src={twitter} alt='social media' className='w-8' />
                    </a>
                </li>
            </ul>
        </div>
    </div >
}