import { useToggleModal } from "../../context"

interface CourseCardProps {
    code: string
    name: string
}

export default function CourseCard({
    name, code
}: CourseCardProps) {
    const toggle = useToggleModal();
    return <div className={`border bg-white border-[#121212] p-2 rounded-lg aspect-[1/1.2] lg:w-full w-[18rem] max-w-[19rem]`}>
        <div className={`bg-[#121212] text-white flex h-3/5 relative rounded-lg`}>
            <p className="text-3xl font-semibold m-auto">{code}</p>
        </div>
        <div className="h-2/5 pt-2 flex flex-col justify-between items-end">

            <p className="text-xl font-semibold w-full">{name}</p>


            <div className="flex gap-3 w-full">
                <p className="text-lg">&#8358;3,600</p>
                <p className="text-lg line-through">&#8358;10,000</p>
            </div>



            <button
                className="underline" onClick={() => toggle(code)}>Join waitlist</button>


        </div>
    </div>
}

