import React from 'react';
import { girl, logo2, logo, batch, arrow } from '../assets';
import { joinWaitList } from '../api';
import { toast } from 'react-toastify';
import Loading from 'react-loading';

export default function Header() {
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");

    async function handleSubmit() {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            toast.error("Enter a valid email format");
            return
        }
        setLoading(true);
        await joinWaitList(email);
        setLoading(false);
        setEmail("")
    }
    return <header className='h-[calc(100vh-3.5rem)] min-h-[600px] max-h-[800px] relative bg-slate-600'>
        <img loading='lazy' src={girl} alt="imae" className='w-full h-full object-cover absolute'/>
        <div className='tutoria-container h-full text-white flex flex-col justify-center gap-2 md:gap-5 pb-24 z-10 relative'>
            <img alt='log2' className='h-8 w-fit' src={logo2} />

            <h1 className='text-3xl lg:text-5xl xl:text-7xl font-semibold' data-aos="fade-up"  data-aos-duration="500">Borderless  Learning</h1>
            <h5 className='text-md lg:text-lg xl:text-2xl' data-aos="fade-up"  data-aos-duration="1000">A whole new way to experience tutorials</h5>

            <div className='max-w-md w-full my-5 md:my-0' data-aos="fade-up"  data-aos-duration="2000">
                <p>Reserve your spot on the waitlist *</p>
                <div className='flex gap-3 mt-4'>
                    <input onBlur={e => setEmail(e.target.value.trim())} value={email} onChange={e => setEmail(e.target.value)} className='w-full md:w-[calc(100%-11rem)] custom-input col-span-7 !py-3' placeholder='Enter email address' />
                    <button onClick={handleSubmit} className='butt w-28 md:w-32 !px-1 text-xs justify-center flex items-center' disabled={loading}>{loading ? <Loading type='spin' color='#121212' className='!w-4 !h-4' /> : 'Join Waitlist'}</button>
                </div>
            </div>

            <div className='justify-items-center md:justify-items-start grid grid-cols-11 max-w-sm gap-y-5' data-aos="fade-up"  data-aos-duration="3000">
                <div className='flex flex-col justify-between gap-1 col-span-5 md:col-span-3 max-w-[100px] md:max-w-none '>
                    <h2 className='font-semibold text-sm'>Discounts and Rewards</h2>
                    <p className='text-xs'>Access up to 100% discount and 5000 NGN 360 credits</p>
                </div>

                <span className='h-[90%] w-0 border-r m-auto border-[#FFF]' />
                <div className='flex flex-col justify-between gap-1 col-span-5 md:col-span-3 max-w-[100px] md:max-w-none '>
                    <h2 className='font-semibold text-sm'>Priority testers</h2>
                    <p className='text-xs'>Get access to paid contents for free as priority testers</p>
                </div>
                <span className='h-[90%] w-0 border-r m-auto border-[#FFF] hidden md:block' />
                <div className='flex flex-col justify-between gap-3 md:gap-1 col-span-full md:col-span-3 max-w-[100px] md:max-w-none '>
                    <div className='flex gap-1'>
                        <img className='h-5' src={logo} alt='logo' />
                        <img className='h-5' src={batch} alt='logo' />
                    </div>
                    <p className='text-xs'>Join the 360 geng. Win gifts,  merch, and many more</p>
                </div>

            </div>

        </div>
        <div className='absolute h-16 md:h-24 bottom-0 text-white flex flex-col items-center justify-center w-full bg-[#121212EB]'>
            <img className='h-4 md:h-8 animate-bounce' src={arrow} alt="arrow" />
            <p>Find out more</p>
        </div>
    </header>
}