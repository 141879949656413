import React from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import MidSection from './components/MidSection';
import EndSection from './components/EndSection';
import Footer from './components/Footer';
import JoinWaitListModal from './components/JoinWaitListModal';
import ContextProvider from './context';
import Courses from './components/Courses';
import JoinCommunity from './components/JoinCommunity';

function App() {

  React.useEffect(() => {
    Aos.init();
  }, []);

  return (
    <ContextProvider>
      <div className="text-[#121212] overflow-x-hidden">
        <Navbar />
        <Header />
        <MidSection />
        <Courses />
        <EndSection />
        <Footer />

        <JoinWaitListModal />
        <JoinCommunity />

        <ToastContainer
          icon={false}
          theme='dark'
        />
      </div>
    </ContextProvider>
  );
}

export default App;
